import React, { useContext, useEffect, useState } from "react";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { GlobalLoaderContext } from "../../../context/global-loader";
import { SnackbarContext } from "../../../context/snackbar";
import API from "../../../api/api";
import ApiUri from "../../../api/api-uri";

import { Grid } from "@mui/material";
import { AuthContext } from "../../../context/auth";
import { LanguagesContext } from "../../../context/languages";
import { TimezonesContext } from "../../../context/timezones";
import { CountriesContext } from "../../../context/countries";
import { CurrenciesContext } from "../../../context/currencies";
import { ReportEncodingsContext } from "../../../context/report-encodings";
import PersonalSettings from "../../account/PersonalSettings";
import PreferencesSettings from "../../account/PreferencesSettings";
import UserHeader from "./UserHeader";
import UserAccess from "./UserAccess";
import DangerZone from "./DangerZone";
import { TranslationsContext } from "../../../context/translations";
import { getCookie } from "../../../App";

const Transition = React.forwardRef(function Transition(
  // made the children prop required
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditUserPage = ({ originalUser, avatarUrl }) => {
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { jwt } = useContext(AuthContext);
  const { languages, setLanguages } = useContext(LanguagesContext);
  const { timezones, setTimezones } = useContext(TimezonesContext);
  const { countries, setCountries } = useContext(CountriesContext);
  const { currencies, setCurrencies } = useContext(CurrenciesContext);
  const { reportEncodings, setReportEncodings } = useContext(
    ReportEncodingsContext
  );
  const { translations } = useContext(TranslationsContext);

  const [account, setAccount] = useState(originalUser as any);
  const [emailIsValid, setEmailIsValid] = React.useState(true);
  const [firstNameIsValid, setFirstNameIsValid] = React.useState(true);
  const [lastNameIsValid, setLastNameIsValid] = React.useState(true);
  const [formIsValid, setFormIsValid] = React.useState(true);

  const roles = [
    { name: translations["heading.user"] ?? "User", id: 0 },
    { name: translations["heading.admin"] ?? "Administrator", id: 1 },
  ];
  const statuses = [
    { name: translations["heading.disabled"] ?? "Suspended", id: 1 },
    { name: translations["heading.active"] ?? "Active", id: 2 },
  ];

  const config = {
    headers: {
      Authorization: `Bearer ${jwt}`,
    },
  };
  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Account` : "Account";

  useEffect(() => {
    const fetchLanguages = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.LANGUAGE, config);
      setLanguages(response.data.data);
    };
    const fetchCountries = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.COUNTRY, config);
      setCountries(response.data.data);
    };
    const fetchTimezones = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.TIMEZONE, config);
      setTimezones(response.data.data);
    };
    const fetchCurrencies = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.CURRENCY, config);
      setCurrencies(response.data.data);
    };
    const fetchReportEncodings = async () => {
      const response = await API.get(
        ApiUri.DATA + ApiUri.REPORT_ENCODINGS,
        config
      );
      setReportEncodings(response.data.data);
    };

    const checkData = async () => {
      startGlobalLoader();
      if (languages.length === 0) {
        fetchLanguages();
      }
      if (countries.length === 0) {
        fetchCountries();
      }
      if (currencies.length === 0) {
        fetchCurrencies();
      }
      if (reportEncodings.length === 0) {
        fetchReportEncodings();
      }
      if (timezones.length === 0) {
        await fetchTimezones();
      }
      stopGlobalLoader();
    };
    checkData();
  }, []);

  useEffect(() => {
    setFormIsValid(emailIsValid && firstNameIsValid && lastNameIsValid);
  }, [emailIsValid, firstNameIsValid, lastNameIsValid]);

  const handleEdit = (event) => {
    const { name, value } = event.target;
    const newAccount = account;
    if (name === "email") {
      const validEmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      setEmailIsValid(value.match(validEmailRegex));
      newAccount.email = value;
    } else if (name === "firstName") {
      setFirstNameIsValid(value.trim().length > 0);
      newAccount.user_profile.first_name = value;
    } else if (name === "lastName") {
      setLastNameIsValid(value.trim().length > 0);
      newAccount.user_profile.last_name = value;
    } else if (name === "country") {
      const selectedCountry = countries?.find(
        (country) => country.id === value
      );
      newAccount.user_profile.country = selectedCountry;
    } else if (name === "timezone") {
      const selectedTimezone = timezones.find(
        (timezone) => timezone.id === value
      );
      newAccount.user_profile.timezone = selectedTimezone;
    } else if (name === "mailing-list") {
      const { checked } = event.target;
      newAccount.user_profile.signed_up_to_mailing_list = checked;
    } else if (name === "language") {
      const selectedLanguage = languages?.find(
        (language) => language.id === value
      );
      newAccount.user_profile.language = selectedLanguage;
    } else if (name === "currency") {
      const selectedCurrency = currencies?.find(
        (currency) => currency.id === value
      );
      newAccount.product_currency = selectedCurrency;
    } else if (name === "encoding") {
      const selectedEncoding = reportEncodings?.find(
        (encoding) => encoding.id === value
      );
      newAccount.report_encoding = selectedEncoding.id;
    } else if (name === "role") {
      newAccount.user_role = value;
    } else if (name === "status") {
      newAccount.status = value;
    } else {
      newAccount[name] = value;
    }

    setAccount((previousState) => ({ ...previousState, ...newAccount }));
  };

  const handleSaveProfile = async (successMessage) => {
    startGlobalLoader();
    const payload = {
      first_name: account?.user_profile?.first_name,
      last_name: account?.user_profile?.last_name,
      country: account?.user_profile?.country?.id,
      timezone: account?.user_profile?.timezone?.id,
      language: account?.user_profile?.language?.id,
      currency: account?.product_currency?.id,
      report_encoding: account?.report_encoding,
      status: account?.status,
      role: account?.user_role,
      signed_up_to_mailing_list:
        account?.user_profile?.signed_up_to_mailing_list,
    };
    API.put(
      ApiUri.ADMIN + ApiUri.USER + ApiUri.UPDATE + "/" + account?.id,
      payload,
      config
    ).then(
      (response) => {
        setAccount(response.data.data);
        if (successMessage) {
          initSnackbarSuccess(successMessage);
        }
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error?.response?.data?.message);
        stopGlobalLoader();
      }
    );
  };

  return (
    <React.Fragment>
      {timezones.length > 0 && (
        <Grid container spacing={3}>
          <UserHeader
            user={account}
            setUser={setAccount}
            originalUser={originalUser}
          />

          <PersonalSettings
            account={account}
            handleAvatarChange={() => {}}
            avatarUrl={avatarUrl}
            formIsValid={formIsValid}
            handleSaveProfile={handleSaveProfile}
            handleEdit={handleEdit}
            emailIsValid={emailIsValid}
            firstNameIsValid={firstNameIsValid}
            lastNameIsValid={lastNameIsValid}
            admin={true}
          />

          <PreferencesSettings
            account={account}
            handleEdit={handleEdit}
            formIsValid={formIsValid}
            handleSaveProfile={handleSaveProfile}
          />

          <UserAccess
            account={account}
            handleEdit={handleEdit}
            roles={roles}
            statuses={statuses}
            formIsValid={formIsValid}
            handleSaveProfile={handleSaveProfile}
          />

          <DangerZone id={account?.id} config={config} />
        </Grid>
      )}
    </React.Fragment>
  );
};

export default EditUserPage;
