import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ActionButtonSmall from "../../components/layout/buttons/action-button-small";
import Colors from "../../enums/colors";
import { useHistory, useLocation } from "react-router-dom";
import { SnackbarContext } from "../../context/snackbar";
import { Link } from "react-router-dom";
import { Country } from "../../models/country/country";
import { LanguagesContext } from "../../context/languages";
import { TimezonesContext } from "../../context/timezones";
import { CountriesContext } from "../../context/countries";
import API from "../../api/api";
import ApiUri from "../../api/api-uri";
import { GlobalLoaderContext } from "../../context/global-loader";
import { Language } from "../../models/language/language";
import BaseComponentLogin from "./baseComponentLogin";
import { isValidEmail } from ".";
import ReCAPTCHA from "react-google-recaptcha";
import { getCookie, setCookie } from "../../App";
import { AuthContext } from "../../context/auth";
import { TranslationsContext } from "../../context/translations";
import { getPasswordValidationTexts } from "../account/PasswordSettings";
import { useParams } from "react-router";
import { InformationContext } from "../../context/information";

const deleteMczProfileTypeCookie = (path = "/") => {
  const hostname = window.location.hostname;
  const domain = hostname.replace("mmaz", "");

  const cookieString = `${
    process.env.REACT_APP_MCZ_PROFILE_TYPE_COOKIE
  }=; Expires=Thu, 01 Jan 1970 00:00:00 UTC; Path=${path};${
    domain ? ` Domain=${domain};` : ""
  }`;
  document.cookie = cookieString;
  document.cookie = cookieString + " Secure;";
  document.cookie = cookieString + " SameSite=None;";
};

const SignUp = () => {
  const emailForSignup = localStorage.getItem("emailForSignup");
  const history = useHistory();
  const [email, setEmail] = useState(emailForSignup || "");
  const [emailError, setEmailError] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [country, setCountry] = useState<Country | undefined>(undefined);
  const [timezone, setTimezone] = useState<any>(undefined);
  const [language, setLanguage] = useState<Language | undefined>(undefined);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const { languages, setLanguages } = useContext(LanguagesContext);
  const { timezones, setTimezones } = useContext(TimezonesContext);
  const { countries, setCountries } = useContext(CountriesContext);
  const { initSnackbarSuccess, initSnackbarError } =
    useContext(SnackbarContext);
  const { startGlobalLoader, stopGlobalLoader } =
    useContext(GlobalLoaderContext);
  const [passwordIsBeingEdited, setPasswordIsBeingEdited] =
    React.useState(false);
  const [passwordHasAtLeast11Characters, setPasswordHasAtLeast11Characters] =
    React.useState(false);
  const [passwordsMatch, setPasswordsMatch] = React.useState(false);
  const [passwordHasAtLeastOneDigit, setPasswordHasAtLeastOneDigit] =
    React.useState(false);
  const [
    passwordHasAtLeastOneUppercaseLetter,
    setPasswordHasAtLeastOneUppercaseLetter,
  ] = React.useState(false);
  const [
    passwordHasAtLeastOneLowercaseLetter,
    setPasswordHasAtLeastOneLowercaseLetter,
  ] = React.useState(false);
  const [
    passwordHasAtLeastOneSpecialCharacter,
    setPasswordHasAtLeastOneSpecialCharacter,
  ] = React.useState(false);
  const {
    profileNameLogin,
    setProfileNameLogin,
    profileImageURLLogin,
    setProfileImageURLLogin,
    profileUrlName,
    setProfileUrlName,
    showSignup,
    setShowSignup,
  } = useContext(AuthContext);
  const [hasFetchedProfileInfo, setHasFetchedProfileInfo] = useState(false);

  if (!showSignup && hasFetchedProfileInfo) {
    history.push("/");
  }

  const { translations } = useContext(TranslationsContext);

  const { setInformation } = useContext(InformationContext);

  const [recaptchaApproved, setRecaptchaApproved] = useState(false);
  function onChangeRecaptcha(value) {
    if (value) {
      setRecaptchaApproved(true);
    } else {
      setRecaptchaApproved(false);
    }
  }
  const location = useLocation();

  const getAppAndProfileFromUrl = () => {
    const { pathname } = location;
    console.log("🚀 ~ getAppAndProfileFromUrl ~ pathname:", pathname);
    const parts = pathname.split("/").filter((part) => part);
    console.log("🚀 ~ getAppAndProfileFromUrl ~ parts:", parts);

    let appFromUrl, profileFromUrl, labelValueFromUrl;

    if (parts.length >= 2) {
      appFromUrl = parts[0];
      profileFromUrl = parts[2];
      labelValueFromUrl = parts[3];
    }
    return { appFromUrl, profileFromUrl, labelValueFromUrl };
  };

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);

  const baseUrlMmaz = `${url.protocol}//${url.host}`;

  const domainUrl = baseUrlMmaz.replace("mmaz", "mmdz");

  const buttonSX = {
    backgroundColor: "#3399FE",
    "&:hover": {
      backgroundColor: "#004c97",
    },
  };
  const { app, profile } = useParams();

  const urlParams = new URLSearchParams(window.location.search);
  const typeFromUrl = urlParams.get("type");

  if (typeFromUrl) {
    setCookie(process.env.REACT_APP_MCZ_PROFILE_TYPE_COOKIE, typeFromUrl, 365);
  } else {
    deleteMczProfileTypeCookie();
  }

  const profileName = getCookie("PROFILE_NAME_TITLE");
  document.title = profileName ? `${profileName} - Sign Up` : "Sign Up";

  useEffect(() => {
    const { profileFromUrl, appFromUrl, labelValueFromUrl } =
      getAppAndProfileFromUrl();
    setCookie(process.env.REACT_APP_MMAZ_PROFILE_COOKIE, profile, 365);
    setCookie(process.env.REACT_APP_MCZ_PROFILE_COOKIE, profile, 365);
    setCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE, appFromUrl, 365);
    const urlParams = new URLSearchParams(window.location.search);
    let labelValueToUse = labelValueFromUrl ?? urlParams.get("type");
    console.log("🚀 ~ useEffect ~ labelValueToUse:", labelValueToUse);

    if (labelValueToUse) {
      setCookie("MCZ_PROFILE_TYPE", labelValueFromUrl, 365);
    }

    if (profileNameLogin && profileImageURLLogin && profileUrlName) {
      setHasFetchedProfileInfo(true);
    } else {
      startGlobalLoader();
      const tokenFromLocalStorage = localStorage.getItem("activationToken");
      let payload: any = {
        domain: window.location.hostname.split(".").slice(1).join("."),
        application: app,
        profile: profile,
      };
      if (tokenFromLocalStorage) {
        payload = { ...payload, token: tokenFromLocalStorage };
      }
      API.post(ApiUri.PROFILE + ApiUri.INFORMATION, payload).then(
        (response) => {
          console.log(
            "🚀 ~ useEffect ~ response.data.data:",
            response.data.data
          );
          if (response.data.data?.invitation?.email) {
            localStorage.setItem(
              "emailForSignup",
              response.data.data?.invitation?.email
            );
            setEmail(response.data.data?.invitation?.email);
          }
          setCookie(
            process.env.REACT_APP_WHITE_LABEL_COOKIE,
            response.data.data.white_label,
            365
          );
          setCookie("PROFILE_NAME_TITLE", response.data.data.profile_name, 365);
          setInformation(response.data.data);
          // todo: set these cookies names in env
          setCookie("APP_NAME", response.data.data.app_name, 365);
          setCookie("WEB", response.data.data.web, 365);

          if (profileFromUrl !== response.data.data.url_name) {
            const newPath = `/${appFromUrl ?? "mmdz"}/${
              response.data.data.url_name
            }/`;
            history.replace(newPath);
          }
          const domainUrl = response.data.data.domain;
          //todo: use value from env
          const domainUrlCookieName = "DOMAIN_URL";
          setCookie(domainUrlCookieName, domainUrl, 365);
          setProfileImageURLLogin(response.data.data.logo_url ?? "");
          setShowSignup(
            response.data.data.is_registration_enabled === false ? false : true
          );
          setProfileNameLogin(
            response.data.data.profile_name ??
              response.data.data.profile_information.name
          );
          setProfileUrlName(response.data.data.url_name);
          stopGlobalLoader();
          setHasFetchedProfileInfo(true);
        },
        (error) => {
          stopGlobalLoader();
          setHasFetchedProfileInfo(true);
        }
      );
    }

    //todo: use value from env

    const fetchLanguages = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.LANGUAGE);
      setLanguages(response.data.data);
    };
    const fetchCountries = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.COUNTRY);
      setCountries(response.data.data);
    };
    const fetchTimezones = async () => {
      const response = await API.get(ApiUri.DATA + ApiUri.TIMEZONE);
      setTimezones(response.data.data);
    };

    const checkData = async () => {
      startGlobalLoader();
      if (languages.length === 0) {
        fetchLanguages();
      }
      if (countries.length === 0) {
        fetchCountries();
      }
      if (timezones.length === 0) {
        await fetchTimezones();
      }
      stopGlobalLoader();
    };
    checkData();
  }, []);

  const handleRegisterButtonPress = (event) => {
    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      repeat_password: repeatPassword,
      country: country?.id,
      timezone: timezone?.id,
      language: language?.id,
      // if the user clicks in the switch, use 1, otherwise 0
      terms_of_use_and_privacy_policy: 1,
      // optional
      newsletter_opt_in: 1,
    };
    const profileCookie = getCookie(process.env.REACT_APP_MMAZ_PROFILE_COOKIE);
    const appCookie = getCookie(process.env.REACT_APP_MMAZ_APPLICATION_COOKIE);
    startGlobalLoader();
    API.post(
      ApiUri.AUTH + ApiUri.SIGNUP + "/" + appCookie + "/" + profileCookie,
      payload
    ).then(
      (response) => {
        initSnackbarSuccess(
          translations.account_not_active_by_url ??
            "Please check your inbox and activate the account using the sent url."
        );
        localStorage.removeItem("emailForSignup");
        history.push("/");
        stopGlobalLoader();
      },
      (error) => {
        initSnackbarError(error.response?.data?.data?.errors[0]?.message);
        stopGlobalLoader();
      }
    );
  };

  const handleSelectChange = (event: any) => {
    const { name, value } = event.target;

    if (name === "country") {
      const selectedCountry = countries?.find(
        (country) => country.id === value
      );
      setCountry(selectedCountry);
    } else if (name === "timezone") {
      const selectedTimezone = timezones.find(
        (timezone) => timezone.id === value
      );
      setTimezone(selectedTimezone);
    } else if (name === "language") {
      const selectedLanguage = languages?.find(
        (language) => language.id === value
      );
      setLanguage(selectedLanguage);
    }
  };

  const handleEditPassword = (event) => {
    let { name, value } = event.target;
    value = value.trim();
    if (!passwordIsBeingEdited) {
      setPasswordIsBeingEdited(true);
    }
    if (name === "password") {
      setPasswordsMatch(value === repeatPassword);
      setPasswordHasAtLeast11Characters(value.length >= 11);
      setPasswordHasAtLeastOneDigit(/\d/.test(value));
      setPasswordHasAtLeastOneUppercaseLetter(/[A-Z]/.test(value));
      setPasswordHasAtLeastOneLowercaseLetter(/[a-z]/.test(value));
      setPasswordHasAtLeastOneSpecialCharacter(
        /[!@#$%^&*(),.?":{}|<>]/.test(value)
      );
      setPassword(value);
    } else if (name === "repeatPassword") {
      setPasswordsMatch(value === password);
      setRepeatPassword(value);
    }
  };

  const passwordsAreInvalid = passwordIsBeingEdited
    ? !passwordHasAtLeast11Characters ||
      !passwordHasAtLeastOneDigit ||
      !passwordsMatch ||
      !passwordHasAtLeastOneLowercaseLetter ||
      !passwordHasAtLeastOneUppercaseLetter ||
      !passwordHasAtLeastOneSpecialCharacter
    : true;

  const handleBlur = () => {
    setEmailError(!isValidEmail(email));
  };

  const emailIsValid = isValidEmail(email);

  const handleChangeEmail = (event) => {
    const input = event.target.value.trim();
    setEmail(input);
    if (emailError) {
      setEmailError(!isValidEmail(input));
    }
  };

  const {
    minimumPasswordLengthText,
    passwordsMatchingText,
    atLeastOneDigitText,
    atLeastOneUppercaseText,
    atLeastOneLowercaseText,
    atLeastOneSpecialText,
  } = getPasswordValidationTexts(translations);

  return (
    <BaseComponentLogin backButton>
      {hasFetchedProfileInfo ? (
        <>
          <img
            src={profileImageURLLogin}
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              overflow: "hidden",
            }}
          />
          <Typography align="center" sx={{ wordBreak: "break-word" }}>
            <h1>{profileNameLogin}</h1>
          </Typography>
          <Stack spacing={2} width={"100%"}>
            <TextField
              fullWidth
              size="small"
              label={translations["label.my_first_name"] ?? "My First Name"}
              value={firstName}
              onChange={(event) => setFirstName(event.target.value)}
            />
            <TextField
              fullWidth
              size="small"
              label={translations["label.my_last_name"] ?? "My Last Name"}
              value={lastName}
              onChange={(event) => setLastName(event.target.value)}
            />
            <TextField
              fullWidth
              size="small"
              label={translations["label.my_email"] ?? "My Email"}
              value={email}
              onChange={handleChangeEmail}
              onBlur={handleBlur}
              error={emailError}
              inputProps={{ autoCapitalize: "none" }}
              disabled={
                typeof emailForSignup === "string" && emailForSignup.length > 0
              }
              // helperText={emailError ? "Invalid email" : ""}
            />
            <TextField
              fullWidth
              size="small"
              label={translations["label.password"] ?? "Password"}
              name="password"
              value={password}
              onChange={handleEditPassword}
              type="password"
            />
            <TextField
              fullWidth
              size="small"
              label={translations["label.repeat_password"] ?? "Repeat Password"}
              name="repeatPassword"
              value={repeatPassword}
              onChange={handleEditPassword}
              type="password"
            />
            <Stack spacing={0.5}>
              <Typography
                variant="subtitle2"
                color={
                  passwordIsBeingEdited
                    ? passwordHasAtLeast11Characters
                      ? "#66bb6a"
                      : Colors.error
                    : Colors.grey
                }
              >
                {minimumPasswordLengthText}
              </Typography>

              <Typography
                variant="subtitle2"
                color={
                  passwordIsBeingEdited
                    ? passwordHasAtLeastOneDigit
                      ? "#66bb6a"
                      : Colors.error
                    : Colors.grey
                }
              >
                {atLeastOneDigitText}
              </Typography>

              <Typography
                variant="subtitle2"
                color={
                  passwordIsBeingEdited
                    ? passwordHasAtLeastOneUppercaseLetter
                      ? "#66bb6a"
                      : Colors.error
                    : Colors.grey
                }
              >
                {atLeastOneUppercaseText}
              </Typography>

              <Typography
                variant="subtitle2"
                color={
                  passwordIsBeingEdited
                    ? passwordHasAtLeastOneLowercaseLetter
                      ? "#66bb6a"
                      : Colors.error
                    : Colors.grey
                }
              >
                {atLeastOneLowercaseText}
              </Typography>

              <Typography
                variant="subtitle2"
                color={
                  passwordIsBeingEdited
                    ? passwordHasAtLeastOneSpecialCharacter
                      ? "#66bb6a"
                      : Colors.error
                    : Colors.grey
                }
              >
                {atLeastOneSpecialText}
              </Typography>

              <Typography
                variant="subtitle2"
                color={
                  passwordIsBeingEdited
                    ? passwordsMatch
                      ? "#66bb6a"
                      : Colors.error
                    : Colors.grey
                }
              >
                {passwordsMatchingText}
              </Typography>
            </Stack>

            <FormControl fullWidth size="small">
              <InputLabel>
                {translations["label.country"] ?? "Country"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={translations["label.country"] ?? "Country"}
                name="country"
                value={country?.id}
                onChange={handleSelectChange}
              >
                {countries?.map((country) => (
                  <MenuItem value={country?.id} key={country?.id}>
                    {country?.country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>
                {translations["label.timezone"] ?? "Timezone"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="timezone"
                value={timezone?.id}
                onChange={handleSelectChange}
                label={translations["label.timezone"] ?? "Timezone"}
              >
                {timezones.map((timezone) => (
                  <MenuItem value={timezone?.id} key={timezone?.id}>
                    {timezone?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth size="small">
              <InputLabel>
                {translations["label.language"] ?? "Language"}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="language"
                value={language?.id}
                onChange={handleSelectChange}
                label={translations["label.language"] ?? "Language"}
              >
                {languages?.map((language) => (
                  <MenuItem value={language?.id} key={language?.id}>
                    {language?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <InputLabel sx={{ marginTop: 2 }}>
              {translations["label.registration_agreement"] ??
                "By registering you agree to the:"}
            </InputLabel>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={termsOfUse}
                    onChange={() => setTermsOfUse(!termsOfUse)}
                    name="termsOfUse"
                    // color="primary"
                    // color="success"
                  />
                }
                label={
                  <Typography>
                    <a
                      href={`${domainUrl}/${profileUrlName}/public/terms-of-use`}
                      style={{ color: "#3399FE" }}
                      target="_blank"
                    >
                      {translations["label.terms_of_use"] ?? "Terms of Use"}
                    </a>{" "}
                    &{" "}
                    <a
                      href={`${domainUrl}/${profileUrlName}/public/privacy-policy`}
                      style={{ color: "#3399FE" }}
                      target="_blank"
                    >
                      {translations["label.privacy_policy"] ?? "Privacy Policy"}
                    </a>
                  </Typography>
                }
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={marketing}
                    onChange={() => setMarketing(!marketing)}
                    name="marketing"
                    // color="primary"
                    // color="success"
                  />
                }
                label={
                  <Typography>
                    <a
                      href={`${domainUrl}/${profileUrlName}/public/marketing`}
                      style={{ color: "#3399FE" }}
                      target="_blank"
                    >
                      {translations["label.marketing_permissions"] ??
                        "Marketing Permissions"}
                    </a>
                  </Typography>
                }
              />
            </FormGroup>
          </Stack>

          <Stack
            alignItems="center"
            width={"100%"}
            marginTop={3}
            spacing={2}
            paddingBottom={2}
          >
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_SITEKEY_RECAPTCHA}
              onChange={onChangeRecaptcha}
            />

            <ActionButtonSmall
              sx={buttonSX}
              onClick={handleRegisterButtonPress}
              disabled={
                email.length === 0 ||
                !emailIsValid ||
                firstName.length === 0 ||
                lastName.length === 0 ||
                !country ||
                !timezone ||
                !language ||
                !termsOfUse ||
                passwordsAreInvalid ||
                !recaptchaApproved
              }
              fullWidth
            >
              {translations["button.register"] ?? "Register"}
            </ActionButtonSmall>
          </Stack>

          <Stack
            spacing={1}
            mt={"auto"}
            alignItems={"center"}
            justifyContent={"center"}
            paddingTop={2}
          >
            <Typography fontSize={15}>
              {translations["label.existing_account"] ??
                "Already have an account?"}{" "}
              <Link to="/" style={{ color: "#3399FE" }}>
                {translations["button.log_in"] ?? "Log in"}
              </Link>
            </Typography>
          </Stack>
        </>
      ) : (
        <CircularProgress
          size={80}
          color="primary"
          style={{ marginBottom: 20 }}
        />
      )}
    </BaseComponentLogin>
  );
};

export default SignUp;
